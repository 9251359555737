<template>
  <div>
    <vTitulo>
      Participantes
    </vTitulo>
    <div class="mx-4 mb-2">
      <vFiltrosDeBusca :loading="loading" @onSearch="pesquisaComFiltros" />
    </div>
    <div class="ma-4">
      <v-data-table
        :headers="headers"
        :items="participantes"
        :options.sync="options"
        :items-per-page="maxPage"
        :server-items-length="totalParticipantes"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100]
        }"
        class="middleShadow"
        loading-text="Carregando..."
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td style="min-width: 80px;">
                USR-{{ item.usuarioId }}
                <!--<xAvatar :avatar-url="item.fotoUrl" />-->
              </td>
              <td>{{ item.nomeFormando }}</td>
              <td>{{ item.nome + " " }} {{ item.sobreNome | DadosNull }}</td>
              <td>{{ item.email }}</td>
              <td style="min-width: 100px;">EVT-{{ item.turmaId }}</td>
              <td style="min-width: 200px;">{{ item.turmaNome }}</td>
              <td>{{ item.permissaoTurma }}</td>
              <td>{{ item.situacaoCadastro }}</td>
              <td
                alt="Participa do Formatura Custo Zero"
                title="Participa do Formatura Custo Zero"
              >
                <vTagSituacaoFCZ :value="dataTransformFCZ(item)" />
              </td>
              <td
                alt="Saldo do Formatura Custo Zero"
                title="Saldo do Formatura Custo Zero"
              >
                <vSaldoFCZ :item="item" />
                <!-- {{ item.saldoBilhete | normalizeNameSaldoBilhete }} -->
              </td>
              <td class="text-center">
                <div class="d-flex flex-row">
                  <v-btn icon @click="openDetalhes(item)">
                    <v-icon size="20" small color="blue" title="Detalhes"
                      >mdi-account-details</v-icon
                    >
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <vDetalhes
      v-if="detalhesToggle"
      :user="userDetalhes"
      @close="closeDetalhes"
    />
  </div>
</template>

<script>
import serviceParticipante from "@/services/participantes";
import vDetalhes from "@/components/DetalhesDoParticipante.vue";
import vFiltrosDeBusca from "./vFiltrosDeBusca.vue";
import vTagSituacaoFCZ from "./vTagSituacaoFCZ.vue";
import vSaldoFCZ from "./vSaldoFCZ.vue";

export default {
  components: {
    vDetalhes,
    vFiltrosDeBusca,
    vTagSituacaoFCZ,
    vSaldoFCZ
  },
  filters: {
    normalizeNameStatusFCZ(value) {
      if (!value) {
        return "Não";
      } else {
        return "Sim";
      }
    },
    normalizeNameSaldoBilhete(value) {
      if (!value) {
        return "-";
      } else {
        return Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(value);
      }
    },
    DadosNull(value) {
      return value || "";
    }
  },
  data() {
    return {
      loading: false,
      detalhesToggle: false,
      userDetalhes: {},
      menu: false,
      busca: "",
      page: 1,
      maxPage: 20,
      totalParticipantes: 0,
      tipoSearch: "nome",
      itensTipoSearch: [
        { text: "Por nome", code: "nome" },
        { text: "Por email", code: "email" },
        { text: "Por id", code: "id" }
      ],
      itensStatusConvite: [
        { text: "Todos", code: null },
        { text: "Autorizado", code: "AUTORIZADO" },
        { text: "Não autorizado", code: "NAO_AUTORIZADO" }
      ],
      itensDeNivel: [
        { text: "Todos", code: null },
        { text: "Admin", code: "ADMIN" },
        { text: "Comissão", code: "COMISSAO" },
        { text: "Participante", code: "PARTICIPANTE" }
      ],
      statusDeCadastro: [
        { text: "Todos", code: null },
        { text: "Incompleto", code: "INCOMPLETO" },
        { text: "Completo", code: "COMPLETO" }
      ],
      filtrosMenu: {},
      filtros: {
        nome: "",
        nomeTurma: null,
        alunoStatus: null,
        conviteStatus: null,
        email: null,
        permissao: null,
        alunoId: null,
        cpf: null,
        rg: null,
        telefone: null,
        pai: null,
        mae: null,
        endereco: {
          cidade: null,
          cep: null,
          logradouro: null,
          uf: null,
          bairro: null
        }
      },
      options: {},
      participantes: [],
      headers: [
        { text: "Código", value: "usuarioId", align: "left", sortable: false },
        {
          text: "Formando",
          value: "nomeFormando",
          align: "left",
          sortable: false
        },
        {
          text: "Nome completo",
          value: "nome",
          align: "left",
          sortable: false
        },
        { text: "Email", value: "email", align: "left", sortable: false },
        { text: "Evento ID", value: "turmaId", align: "left", sortable: false },
        {
          text: "Evento/Turma",
          value: "turmaNome",
          align: "left",
          sortable: false
        },
        {
          text: "Nível",
          value: "permissaoTurma",
          align: "left",
          sortable: false
        },
        {
          text: "Cadastro",
          value: "situacaoCadastro",
          align: "left",
          sortable: false
        },
        {
          text: "Participa do FCZ",
          value: "aceiteFCZ",
          align: "left",
          sortable: false
        },
        {
          text: "Saldo FCZ",
          value: "saldoBilhete",
          align: "left",
          sortable: false
        },
        { text: "Ações", align: "center", sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.maxPage = d.itemsPerPage;
        this.consultaLista();
      },
      deep: true
    }
  },
  mounted() {
    this.consultaLista();
  },
  methods: {
    dataTransformFCZ(item) {
      return item.aceiteFCZ ? item.aceiteFCZ : false;
    },
    pesquisaComFiltros(eventPayload) {
      this.filtros = { ...eventPayload };
      this.consultaLista();
    },
    openDetalhes(user) {
      this.userDetalhes = user;
      this.detalhesToggle = true;
    },
    closeDetalhes() {
      this.detalhesToggle = false;
    },
    toggledEmailName() {
      //old
      this.filtros.nome = "";
      this.filtros.email = "";
    },
    setNivelFiltro(nivel) {
      this.filtros.permissao = nivel;
    },
    setCadastroStatus(status) {
      this.filtros.alunoStatus = status;
    },
    consultaLista() {
      this.loading = true;
      const filtrosPayload = this.filtros;
      serviceParticipante
        .getParticipantes(this.page - 1, this.maxPage, filtrosPayload)
        .then(resp => {
          this.participantes = resp.data.content;
          this.totalParticipantes = resp.data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.$root.showError(err?.data?.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.customShadow {
  box-shadow: 0px 0px 4px 1px rgba(50, 50, 80, 0.3) !important;
  //border: 2px solid red;
}
</style>
